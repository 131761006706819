<template>
  <div class="signIn">
    <amplify-authenticator username-alias="email">
      <amplify-sign-in
        slot="sign-in"
        :hide-sign-up="true"
        username-alias="email"
      >
      </amplify-sign-in>
    </amplify-authenticator>
  </div>
</template>

<script>
import "@aws-amplify/ui-vue";
import { Hub } from "aws-amplify";
// import { AmplifyEventBus } from "aws-amplify-vue";
export default {
  data() {
    return {
      authConfig: {
        signInConfig: {
          isSignUpDisplayed: false,
        },
      },
      username: "",
      message: {
        showError: false,
        errorMessage: null,
        show: false,
        message: null,
      },
    };
  },
  async created() {
    Hub.listen("auth", async (state) => {
      if (state.payload.event === "signIn") {
        await this.$store.dispatch("authAction");
        console.log(this.$store.state.user,"state user");
        this.$router.push({ path: "/" }, () => {});
      }
    });
  },
  computed: {
    signedIn() {
      return this.$store.state.signedIn;
    },
  },
};
</script>